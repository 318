import { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as React from "react";
import { type Show, getShowUrl } from "./data";

export interface VideoPlayerProps {
	show: Show;
	episode: string;
	startingTimestamp: number;
	onUpdateTimestamp: (timestamp: number) => void;
	onPreviousEpisode: () => void;
	onNextEpisode: () => void;
	onClose: () => void;
}

export function VideoPlayer(props: VideoPlayerProps) {
	const videoRef = useRef<HTMLVideoElement>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useLayoutEffect(() => {
		if (!videoRef.current) return;

		// Set the starting timestamp when the episode changes
		videoRef.current.currentTime = props.startingTimestamp;
	}, [props.startingTimestamp, props.episode]);

	const [showControlsInstructions, setShowControlsInstructions] =
		useState(true);

	const [showOverlay, setShowOverlay] = useState(true);

	const [playing, setPlaying] = useState(false);

	const hideControlsTimerRef = useRef<number | null>(null);

	function resetHideControlsTimer() {
		if (hideControlsTimerRef.current) {
			clearTimeout(hideControlsTimerRef.current);
		}

		hideControlsTimerRef.current = window.setTimeout(() => {
			setShowOverlay(false);
		}, 3000);
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// Reset the hide controls timer when the overlay is shown
		if (showOverlay) {
			resetHideControlsTimer();
		}
	}, [showOverlay]);

	useEffect(() => {
		if (!playing) return;
		if (!videoRef.current) return;

		const interval = setInterval(() => {
			if (!videoRef.current) return;
			props.onUpdateTimestamp(videoRef.current.currentTime);
		}, 1000);

		return () => clearInterval(interval);
	}, [playing, props.onUpdateTimestamp]);

	return (
		<div className="VideoPlayer">
			{/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
			{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<video
				ref={videoRef}
				src={getShowUrl(props.show, props.episode)}
				autoPlay
				// controls
				onEnded={props.onNextEpisode}
				loop={false}
				playsInline
				onPlay={() => {
					setPlaying(true);
				}}
				onPause={() => {
					setPlaying(false);
				}}
				onClick={() => {
					if (showOverlay) setShowOverlay(false);
					else props.onClose();
				}}
			/>

			{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div
				className="overlay"
				style={{ opacity: showOverlay ? 1 : 0 }}
				onClick={(e) => {
					// If clicking directly on the div (not on a control) and the controls are showing (not the instructions),
					// then toggle the overlay.
					if (e.currentTarget === e.target) {
						// If the instructions are showing, show the overlay
						if (showControlsInstructions) setShowOverlay(true);
						// Otherwise, toggle the overlay
						else setShowOverlay((v) => !v);
					}

					// Hide the control instructions when clicking on the overlay
					setShowControlsInstructions(false);

					// Hide the controls after 3 seconds of inactivity
					resetHideControlsTimer();
				}}
			>
				{showControlsInstructions ? (
					<div style={{ width: "100%", pointerEvents: "none" }}>
						tap the top of screen at any time for controls
					</div>
				) : (
					<>
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							style={{ pointerEvents: !showOverlay ? "none" : "auto" }}
							onClick={() => {
								if (!videoRef.current) return;

								videoRef.current.currentTime -= 30;
							}}
						>
							&lt; 30 seconds
						</div>

						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							style={{ pointerEvents: !showOverlay ? "none" : "auto" }}
							onClick={() => {
								if (!videoRef.current) return;

								// if current video timestamp is less than 5 seconds, go to previous episode
								// else restart the current episode
								if (videoRef.current.currentTime < 5) {
									props.onPreviousEpisode();
								} else {
									videoRef.current.currentTime = 0;
								}
							}}
						>
							previous
						</div>

						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							style={{ pointerEvents: !showOverlay ? "none" : "auto" }}
							onClick={() => {
								// if video is paused, play; if playing, pause
								if (!videoRef.current) return;

								if (videoRef.current.paused) {
									videoRef.current.play();
								} else {
									videoRef.current.pause();
								}
							}}
						>
							{/*{videoRef.current?.paused ? <>&#x25B6;&#xFE0F;</> : <>&#x23F8;</>}*/}
							{videoRef.current?.paused ? <>play</> : <>pause</>}
						</div>

						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							style={{ pointerEvents: !showOverlay ? "none" : "auto" }}
							onClick={props.onNextEpisode}
						>
							next
						</div>

						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							style={{ pointerEvents: !showOverlay ? "none" : "auto" }}
							onClick={() => {
								if (!videoRef.current) return;

								videoRef.current.currentTime += 30;
							}}
						>
							30 seconds &gt;
						</div>
					</>
				)}
			</div>
		</div>
	);
}
