export interface UserState {
	shows: {
		[title: string]: {
			currentEpisode: string;
			timestamp: number;
		};
	};
}

export function getSavedUserState() {
	let userState: UserState | null = null;
	try {
		userState = JSON.parse(localStorage.getItem("userState") || "null");
	} catch (e) {}
	if (!userState) userState = { shows: {} };

	return userState;
}

export type ShowsData = Show[];

export interface Show {
	title: string;
	episodes: string[];
}

const URL_S3_BUCKET = "https://granny-tv.brombal.com";

export function getShowUrl(show: Show, path: string) {
	return `${URL_S3_BUCKET}/shows/${show.title}/${path}`;
}

export const URL_SHOWS_JSON = `${URL_S3_BUCKET}/shows.json`;

export async function getShowsData() {
	const res = await fetch(URL_SHOWS_JSON);
	return (await res.json()) as Promise<ShowsData>;
}
