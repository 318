import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./app.scss";
import { useEffect, useRef, useState } from "react";
import { ShowsList } from "./ShowsList";
import { VideoPlayer } from "./VideoPlayer";
import {
	type Show,
	type ShowsData,
	type UserState,
	getSavedUserState,
	getShowsData,
} from "./data";

function App() {
	const [selectedShow, setSelectedShow] = useState<Show | null>(null);
	const [currentEpisode, setCurrentEpisode] = useState("");
	const [startingTimestamp, setStartingTimestamp] = useState(0);

	const [userState, setUserState] = useState<UserState | null>();

	const wakeLockRef = useRef<WakeLockSentinel | null>(null);

	const [showsData, setShowsData] = useState<ShowsData | null>();

	const firstVisible = useRef(true);

	useEffect(() => {
		const u = getSavedUserState();
		setUserState(u);
		console.log("App: mount; userState", u);

		(async () => {
			setShowsData(await getShowsData());
		})();

		const handleVisibilityChange = () => {
			console.log("visibilitychange", document.visibilityState);

			// If this is not the first event, refresh the page
			if (!firstVisible.current) {
				window.location.reload();
			} else {
				firstVisible.current = false;
			}
		};
		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			if (wakeLockRef.current) {
				wakeLockRef.current.release();
			}

			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	if (!showsData) {
		return "loading...";
	}

	return (
		<>
			{selectedShow && (
				<VideoPlayer
					show={selectedShow}
					episode={currentEpisode}
					startingTimestamp={startingTimestamp}
					onUpdateTimestamp={(timestamp) => {
						setUserState((prev) => {
							if (!prev) return null;

							console.log("onUpdateTimestamp", timestamp, currentEpisode);

							const newShows = {
								...prev.shows,
								[selectedShow.title]: {
									currentEpisode,
									timestamp,
								},
							};

							const newState = { ...prev, shows: newShows };

							localStorage.setItem("userState", JSON.stringify(newState));

							return newState;
						});
					}}
					onClose={() => setSelectedShow(null)}
					onPreviousEpisode={() => {
						const index = selectedShow.episodes.indexOf(currentEpisode);
						if (index === 0) {
							setSelectedShow(null);
						} else {
							setCurrentEpisode(selectedShow.episodes[index - 1]);
							setStartingTimestamp(0);
						}
					}}
					onNextEpisode={() => {
						const index = selectedShow.episodes.indexOf(currentEpisode);
						console.log("App: onNextEpisode", index);
						if (index === selectedShow.episodes.length - 1) {
							setCurrentEpisode(selectedShow.episodes[0]);
							setStartingTimestamp(0);
						} else {
							setCurrentEpisode(selectedShow.episodes[index + 1]);
							setStartingTimestamp(0);
						}
					}}
				/>
			)}

			<ShowsList
				shows={showsData}
				onChooseShow={(show) => {
					console.log(
						"App: setting current episode",
						show,
						userState?.shows[show.title]?.currentEpisode,
					);
					setSelectedShow(show);
					setCurrentEpisode(
						userState?.shows[show.title]?.currentEpisode || show.episodes[0],
					);
					setStartingTimestamp(userState?.shows[show.title]?.timestamp || 0);
				}}
			/>
		</>
	);
}

// biome-ignore lint/style/noNonNullAssertion: not necessary
ReactDOM.createRoot(document.getElementById("app")!).render(<App />);
