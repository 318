import * as React from "react";
import { type Show, getShowUrl } from "./data";

interface ShowsListProps {
	onChooseShow: (show: Show) => void;
	shows: Show[];
}

export function ShowsList(props: ShowsListProps) {
	const { onChooseShow, shows } = props;

	return (
		<div className="ShowsIndex">
			{shows.map((show) => (
				<div key={show.title} className="show">
					<div className="container">
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: not necessary */}
						<img
							src={getShowUrl(show, "thumb.jpg")}
							className="thumb"
							alt="Thumbnail"
							onClick={() => onChooseShow(show)}
						/>
					</div>
				</div>
			))}
		</div>
	);
}
